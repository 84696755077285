.texticon::after {
  display: block;
  clear: both;
  content: "";
}
.texticon > a {
  transition: color 0.15s ease-in-out;
}
.texticon > a:hover {
  color: #fa4242;
  transition: color 0.15s ease-in-out;
}
.texticon .frame-header > *:last-child {
  margin-bottom: 0.5rem;
}
@media (min-width: 1104px) {
  .texticon .frame-header > *:last-child {
    margin-bottom: 1rem;
  }
}
@media (max-width: 1103.98px) {
  .texticon .texticon-icon .icon {
    width: 68px;
    height: 68px;
  }
}
.texticon-above .texticon-icon,
.texticon-above .texticon-text {
  flex: 0 0 100%;
  max-width: 100%;
}
.texticon-above .texticon-icon {
  text-align: center;
  margin-bottom: 0.375rem;
}
@media (min-width: 1104px) {
  .texticon-above .texticon-icon {
    margin-bottom: 1.375rem;
  }
}
.texticon-left .texticon-icon {
  position: relative;
  min-height: 1px;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
@media (min-width: 576px) {
  .texticon-left .texticon-icon .icon {
    margin-right: 1rem;
  }
}
@media (min-width: 1104px) {
  .texticon-left .texticon-icon .icon {
    margin-right: 3.5rem;
  }
}
.texticon-left .texticon-text > *:first-child {
  margin-top: 0;
}
.texticon-left .texticon-text > *:last-child {
  margin-bottom: 0;
}
.texticon-topleft .texticon-icon {
  position: relative;
  min-height: 1px;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
@media (min-width: 576px) {
  .texticon-topleft .texticon-icon .icon {
    margin-right: 1rem;
  }
}
@media (min-width: 1104px) {
  .texticon-topleft .texticon-icon .icon {
    margin-right: 3.5rem;
  }
}
.texticon-topleft .texticon-text > *:first-child {
  margin-top: 0;
}
.texticon-topleft .texticon-text > *:last-child {
  margin-bottom: 0;
}