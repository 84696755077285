// Texticon

.texticon {
    @include clearfix();

    > a {
        transition: color 0.15s ease-in-out;
        &:hover {
            color: $primary;
            transition: color 0.15s ease-in-out;
        }
    }

    .frame-header > *:last-child {
        margin-bottom: 0.5rem;

        @include media-breakpoint-up(xl) {
            margin-bottom: 1rem;
        }
    }

    .texticon-icon {
        @include media-breakpoint-down(lg) {
            .icon {
                width: 68px;
                height: 68px;
            }
        }
    }

    &-above {
        .texticon-icon,
        .texticon-text {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .texticon-icon {
            text-align: center;
            margin-bottom: 0.375rem;

            @include media-breakpoint-up(xl) {
                margin-bottom: 1.375rem;
            }
        }
    }

    &-left {
        .texticon-icon {
            position: relative;
            min-height: 1px;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;

            .icon {
                @include media-breakpoint-up(sm) {
                    margin-right: 1rem;
                }
                @include media-breakpoint-up(xl) {
                    margin-right: 3.5rem;
                }

            }

        }

        .texticon-text {
            > *:first-child {
                margin-top: 0;
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-topleft {
        .texticon-icon {
            position: relative;
            min-height: 1px;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;

            .icon {
                @include media-breakpoint-up(sm) {
                    margin-right: 1rem;
                }
                @include media-breakpoint-up(xl) {
                    margin-right: 3.5rem;
                }

            }

        }

        .texticon-text {
            > *:first-child {
                margin-top: 0;
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }
}
